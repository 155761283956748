//民主评议列表
const rightsCommentListUrl = `/gateway/hc-neighbour/cpAssessment/getCpAssessmentList`;
//民主评议信息
const rightsCommentDetailUrl = `/gateway/hc-neighbour/cpAssessment/getCpAssessmentInfoById`;
//民主评议编辑
const editRightsCommentUrl = `/gateway/hc-neighbour/cpAssessment/saveCpAssessmentInfo`;
//删除
const deleteRightsCommentUrl = `/gateway/hc-neighbour/cpAssessment/deleteCpAssessment`;
export {
  rightsCommentListUrl,
  rightsCommentDetailUrl,
  editRightsCommentUrl,
  deleteRightsCommentUrl,
};
