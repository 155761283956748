import { mapHelper } from "@/utils/common.js";

// 评议等级
const commentGrade = [
  {
    value: 1,
    label: "优秀",
  },
  {
    value: 2,
    label: "合格",
  },
  {
    value: 3,
    label: "基本合格",
  },
  {
    value: 4,
    label: "不合格",
  },
];

const { map: commentGradeMap, setOps: commentGradeOps } =
  mapHelper.setMap(commentGrade);
// 	评议类别
const commentKinds = [
  {
    value: 1,
    label: "锋领考核",
  },
  {
    value: 2,
    label: "民主评议",
  },
];

const { map: commentKindsMap, setOps: commentKindsOps } =
  mapHelper.setMap(commentKinds);
export { commentGrade, commentGradeMap, commentKindsMap, commentKinds };
