<template>
  <div class="rightsComment">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot
        ><v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParam.name" />
        <v-input label="职务" v-model="searchParam.post" />
        <v-select
          placeholder="评议等级"
          :options="commentGrade"
          v-model="searchParam.assessmentGrade"
          @change="$refs.list.search()"
          label="评议等级"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          @click="toEdit(scope.row)"
        />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { rightsCommentListUrl, deleteRightsCommentUrl } from "./api.js";
import { commentGrade, commentGradeMap } from "./map.js";
import {
  createAlinkVNode,
  createImgVNode,
  createHTMLVNode,
} from "@/utils/utils.js";

export default {
  name: "realNameAuditList",
  data() {
    return {
      commentGrade,
      searchParam: {
        name: "",
        post: "",
        assessmentGrade: "",
      },

      tableUrl: rightsCommentListUrl,
      headers: [
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "post",
          label: "职务",
        },
        {
          prop: "assessmentGrade",
          label: "评议等级",
          formatter: (row, prop) => {
            return commentGradeMap[row.assessmentGrade];
          },
        },

        {
          prop: "sortIndex",
          label: "排序",
        },
      ],
    };
  },
  computed: {},
  methods: {
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteRightsCommentUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },

    toAdd() {
      this.$router.push({
        name: "rightsCommentForm",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "rightsCommentForm",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.rightsComment {
  box-sizing: border-box;
  height: 100%;
}
</style>
